<template>
  <div class="container">
    <Header>
      <template v-slot:title>
        <span>{{ $t('createReservation') }}</span>
      </template>
    </Header>
    <div class="main">
      <div class="reservation">
        <h4 style="color: red;">{{ $t(`reservationStatus.${reservation.status}`) }}</h4>
        <div class="list">
          <div class="list-item">
            <van-row type="flex" gutter="8">
              <van-col span="8">
                <van-image height="100" lazy-load fit="cover" :src="reservation.roomType && reservation.roomType.image || '#'" />
              </van-col>
              <van-col span="16">
                <div class="hotel-name">{{ reservation.hotel.name }}</div>
                <div class="room-type-name">{{ reservation.roomType.name }}</div>
                <div class="breakfast">{{ reservation.roomType.breakfast }}</div>
              </van-col>
            </van-row>

            <van-divider />
          </div>
        </div>

        <div class="date">
          <van-row type="flex">
            <van-col span="8" style="text-align: center;">
              <div class="arrival">
                <div>{{ $t('checkin') }}</div>
                <div>{{ reservation.arrival }}</div>
              </div>
            </van-col>
            <van-col span="8" style="display: flex; flex-direction: row;  align-items: center; justify-content: center;">
              <div class="nights">
                {{ nights }} {{ $t('nights') }}
              </div>
            </van-col>
            <van-col span="8" style="text-align: center;">
              <div class="departure">
                <div>{{ $t('checkout') }}</div>
                <div>{{ reservation.departure }}</div>
              </div>
            </van-col>
          </van-row>
        </div>

        <van-divider />

        <div class="block">
          <h2>{{ $t('yourInformation') }}</h2>
          <van-cell-group>
            <van-field
              v-model="reservation.contact_number"
              label-width="100px"
              :label="$t('mobileNumber')"
              input-align="right"
              readonly
            />
            <van-field
              v-model="reservation.email"
              label-width="100px"
              :label="$t('email')"
              input-align="right"
              readonly
            />
            <van-field
              v-model="reservation.first"
              label-width="100px"
              :label="$t('firstName')"
              input-align="right"
              readonly
            />
            <van-field
              v-model="reservation.last"
              label-width="100px"
              :label="$t('lastName')"
              input-align="right"
              readonly
            />
            <van-field
              :value="$t(reservation.gender)"
              label-width="100px"
              :label="$t('gender')"
              input-align="right"
              readonly
            />

            <van-field
              :value="$t(reservation.id_type)"
              label-width="100px"
              :label="$t('idType')"
              input-align="right"
              readonly
            />

            <van-field
              v-model="reservation.id_number"
              label-width="100px"
              :label="$t('idNumber')"
              input-align="right"
              readonly
            />
            <van-field
              v-model="reservation.invitation_code"
              label-width="100px"
              :label="$t('invitationCode1')"
              input-align="right"
              readonly
            />
            <van-field
              v-model="reservation.accompany"
              label-width="100px"
              :label="$t('accompany')"
              input-align="right"
              readonly
            />
          </van-cell-group>
        </div>

        <div class="block">
          <h2>{{ $t('bookingInformation') }}</h2>
          <van-cell-group>
            <van-field
              v-model="reservation.booking_number"
              label-width="100px"
              :label="$t('bookingNumber')"
              input-align="right"
              readonly
            />
            <van-field
              v-model="reservation.confirm_number"
              label-width="100px"
              :label="$t('hotelConfirmNumber')"
              input-align="right"
              readonly
            />
            <van-field
              :value="arrivalTimeLabel"
              label-width="100px"
              :label="$t('arrivalTime')"
              input-align="right"
              readonly
            />
            <van-field
              v-model="departureTimeLabel"
              label-width="100px"
              :label="$t('departureTime')"
              input-align="right"
              readonly
            />
          </van-cell-group>
        </div>
      </div>
    </div>

    <div class="footer" style="height: 50px;">
      <div>{{ $t('total') }}</div>
      <div class="rate" style="flex: 1; text-align: center;">{{ reservation.currency_code }}{{ reservation.total_amount | formatNumber }}</div>
      <div style="width: 50px; text-decoration: underline;" @click="showDetail = !showDetail">{{ $t('detail') }}</div>
      <div>
      </div>
    </div>
    <van-popup v-model="showDetail" position="bottom" :overlay="false" class="padding-bottom">
      <van-cell-group>
        <van-cell v-for="(item, index) of details" :key="index" :title="item.reservation_date" :value="reservation.currency_code+formatNumber(reservation.rate_amount)" :get-container="getContainer" />
      </van-cell-group>
    </van-popup>
    <van-overlay :show="showDetail" @click="showDetail = false" />
  </div>
</template>

<script>
import _ from 'lodash'
import dayjs from 'dayjs'
import Header from '@/components/Header'
import Hotel from '@/models/hotel'
import Reservation from '@/models/reservation'
import RoomType from '@/models/roomType'
import { getDates, reservationTimes } from '@/utils'
import { formatNumber, getLabel } from '@/filters'
import { Notify } from 'vant'

export default {
  name: 'ReservationDetail',
  components: {
    Header
  },
  data () {
    return {
      showDetail: false,
      reservation: new Reservation({
        arrival: '',
        departure: '',
        contact_number: '',
        email: '',
        first: '',
        last: '',
        id_number: '',
        invitation_code: '',
        hotel: new Hotel(),
        roomType: new RoomType()
      })
    }
  },
  computed: {
    nights () {
      return dayjs(this.reservation.departure).diff(this.reservation.arrival, 'day').toFixed(0)
    },
    details () {
      const dates = getDates(this.reservation.arrival, this.reservation.departure)
      if (dates.length > 1) {
        dates.pop()
      }

      return dates.map(e => ({
        reservation_date: e,
        rate_amount: this.reservation.rate_amount
      }))
    },
    arrivalTimeLabel () {
      return getLabel(_.find(reservationTimes, { code: this.reservation.arrival_time }))
    },
    departureTimeLabel () {
      return getLabel(_.find(reservationTimes, { code: this.reservation.departure_time }))
    }
  },
  async mounted () {
    try {
      const res = await this.$axios.get(`/reservation/${this.$route.params.reservationId}`)
      this.reservation = res.data
    } catch (e) {
      Notify(e.message)
    }
  },
  methods: {
    formatNumber,
    getContainer () {
      return document.querySelector('.main')
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  padding-top: 64px;
  padding-bottom: 50px;
}

.reservation {
  padding: 1rem;
}

.date {
  padding: 14px 0;
  .nights {
    border: 1px solid #CCCCCC;
    padding: 5px 10px;
    border-radius: 50%;
    color: #7C7C7C;
  }
}

.guests {
  .van-row {
    padding-top: 10px;
  }
  padding-bottom: 10px;
}

.hotel-name {
  margin-top: .3rem;
  font-size: 1.2rem;
}

.room-type-name {
  margin-top: .3rem;
  font-size: 1rem;
  color: #2766ba;
}

.breakfast {
  color: #5c5c5c;
  margin-top: .3rem;
}

.policy {
  color: #2766ba;
}

ul {
  list-style: inside;
  li {
    color: #5c5c5c;
    margin-bottom: 10px;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1em;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  border-top: 1px solid #dedede;
  z-index: 10;
}

.padding-bottom.van-popup {
  bottom: 50px;
}
</style>
